import VaultInfoEntity from './VaultInfoEntity';
import VaultPreferences from './VaultPreferences';
export const mapVaultInfoToEntity = (vaultInfo) => new VaultInfoEntity(vaultInfo);
export const mapVaultPreferencesToEntity = (vaultPreferences) => new VaultPreferences(vaultPreferences);
export const mapVaultPreferencesContactsToUpdate = (contacts) => contacts.map(c => {
    return {
        userId: c.contact.userId,
        selectionType: c.documentSelectionType,
        documents: c.documents.map(d => d.documentId),
    };
});
