import { useCallback } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSetVaultPreferencesCache, vaultQueryKeys } from './cache';
import { mapVaultInfoToEntity, mapVaultPreferencesToEntity } from './helpers';
const STALE_30_MINUTES = 1800000;
const createVaultModule = ({ vaultApi }) => {
    const useVaultInfo = () => {
        const { data, isLoading, error, refetch } = useQuery({
            queryKey: vaultQueryKeys.getVaultInfo(),
            queryFn: () => vaultApi.getVaultInfo().then(mapVaultInfoToEntity),
            staleTime: STALE_30_MINUTES,
        });
        return {
            vaultInfo: data,
            error,
            isLoading,
            refetch,
        };
    };
    const useVaultPreferences = () => {
        const { data, isLoading, error, refetch } = useQuery({
            queryKey: vaultQueryKeys.getPreferences(),
            queryFn: () => vaultApi.getPreferences().then(mapVaultPreferencesToEntity),
            staleTime: STALE_30_MINUTES,
        });
        return {
            vaultPreferences: data,
            error,
            isLoading,
            refetch,
        };
    };
    const useUpdateVaultPreferences = () => {
        const setVaultPreferencesCache = useSetVaultPreferencesCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => vaultApi.updatePreferences(values),
            onSuccess(preferences) {
                setVaultPreferencesCache(mapVaultPreferencesToEntity(preferences));
            },
        });
        return {
            updateVaultPreferences: mutateAsync,
            isPending,
            error,
        };
    };
    const useUpdateVaultPreferencesTransferTarget = () => {
        const { updateVaultPreferences, error, isPending } = useUpdateVaultPreferences();
        const updateVaultPreferencesTransferTarget = useCallback((value) => updateVaultPreferences({
            afterDeath: {
                transferTarget: value ? 'HEIRS_AND_CONTACTS' : 'NOBODY',
                heirsDocumentSelection: {
                    selectionType: 'ALL',
                },
            },
        }), [updateVaultPreferences]);
        return {
            updateVaultPreferencesTransferTarget,
            isPending,
            error,
        };
    };
    const useUpdateVaultPreferencesDelete = () => {
        const { vaultPreferences } = useVaultPreferences();
        const { updateVaultPreferences, error, isPending } = useUpdateVaultPreferences();
        const updateVaultPreferencesDelete = useCallback((recipientId) => {
            if (!vaultPreferences) {
                throw new Error('Attempted to delete vault transfer, without data');
            }
            const { heirsDocumentSelection } = vaultPreferences.afterDeath;
            const filteredContacts = vaultPreferences.afterDeath.contactsDocumentSelection
                .map(c => {
                return {
                    userId: c.contact.userId,
                    selectionType: c.documentSelectionType,
                    documents: c.documents.map(d => d.documentId),
                };
            })
                .filter(c => c.userId !== recipientId);
            const newHeirsDocumentSelection = {
                selectionType: vaultPreferences?.isHeirsAll
                    ? 'ALL'
                    : 'SPECIFIC_SELECTION',
                documents: heirsDocumentSelection.documents.map(d => d.documentId),
            };
            return updateVaultPreferences({
                afterDeath: {
                    transferTarget: 'HEIRS_AND_CONTACTS',
                    heirsDocumentSelection: newHeirsDocumentSelection,
                    contactsDocumentSelection: filteredContacts,
                },
            });
        }, [vaultPreferences, updateVaultPreferences]);
        return {
            updateVaultPreferencesDelete,
            isPending,
            error,
        };
    };
    const useUpdateVaultPreferencesSaveWholeVault = () => {
        const { vaultPreferences } = useVaultPreferences();
        const { updateVaultPreferences, isPending, error } = useUpdateVaultPreferences();
        const updateVaultPreferencesSaveWholeVault = useCallback((recipientId) => {
            if (!vaultPreferences) {
                throw new Error('Attempted to save vault transfer whole vault, without data');
            }
            const oldContactsDocumentSelection = vaultPreferences.afterDeath.contactsDocumentSelection.map(c => {
                return {
                    userId: c.contact.userId,
                    selectionType: c.documentSelectionType,
                    documents: c.documents.map(d => d.documentId),
                };
            });
            let newContactsDocumentSelection = [];
            if (recipientId) {
                const isExistingContact = oldContactsDocumentSelection.some(c => c.userId === recipientId);
                if (isExistingContact) {
                    newContactsDocumentSelection = oldContactsDocumentSelection.map(c => c.userId === recipientId
                        ? { ...c, selectionType: 'ALL', documents: undefined }
                        : c);
                }
                else {
                    newContactsDocumentSelection = [
                        ...oldContactsDocumentSelection,
                        {
                            userId: recipientId,
                            selectionType: 'ALL',
                            documents: undefined,
                        },
                    ];
                }
            }
            return updateVaultPreferences({
                afterDeath: {
                    transferTarget: 'HEIRS_AND_CONTACTS',
                    contactsDocumentSelection: newContactsDocumentSelection,
                    heirsDocumentSelection: { selectionType: 'ALL' },
                },
            });
        }, [vaultPreferences, updateVaultPreferences]);
        return {
            updateVaultPreferencesSaveWholeVault,
            isPending,
            error,
        };
    };
    const useUpdateVaultPreferencesSaveSpecific = () => {
        const { vaultPreferences } = useVaultPreferences();
        const { updateVaultPreferences, isPending, error } = useUpdateVaultPreferences();
        const updateVaultPreferencesSaveSpecific = useCallback((selectedDocumentIds, recipientId) => {
            if (!vaultPreferences) {
                throw new Error('Attempted to save vault transfer specific, without data');
            }
            const oldContactsDocumentSelection = vaultPreferences.afterDeath.contactsDocumentSelection.map(c => {
                return {
                    userId: c.contact.userId,
                    selectionType: c.documentSelectionType,
                    documents: c.documents.map(d => d.documentId),
                };
            });
            if (!recipientId) {
                const newContactsDocumentSelection = oldContactsDocumentSelection.map(contact => {
                    const filteredDocuments = contact.documents?.filter(documentId => selectedDocumentIds.includes(documentId)) ?? selectedDocumentIds;
                    return {
                        ...contact,
                        selectionType: 'SPECIFIC_SELECTION',
                        documents: filteredDocuments.length
                            ? filteredDocuments
                            : selectedDocumentIds,
                    };
                });
                return updateVaultPreferences({
                    afterDeath: {
                        transferTarget: 'HEIRS_AND_CONTACTS',
                        contactsDocumentSelection: newContactsDocumentSelection,
                        heirsDocumentSelection: {
                            selectionType: 'SPECIFIC_SELECTION',
                            documents: selectedDocumentIds,
                        },
                    },
                });
            }
            const newHeirsDocumentSelection = {
                selectionType: vaultPreferences?.isHeirsAll
                    ? 'ALL'
                    : 'SPECIFIC_SELECTION',
                documents: vaultPreferences?.isHeirsAll
                    ? []
                    : [...vaultPreferences.oldHeirsDocumentIds, ...selectedDocumentIds],
            };
            const isExistingContact = oldContactsDocumentSelection.some(c => c.userId === recipientId);
            const updatedContact = {
                userId: recipientId,
                selectionType: 'SPECIFIC_SELECTION',
                documents: selectedDocumentIds,
            };
            const contactsDocumentSelection = isExistingContact
                ? oldContactsDocumentSelection.map(c => c.userId === recipientId ? updatedContact : c)
                : [...oldContactsDocumentSelection, updatedContact];
            return updateVaultPreferences({
                afterDeath: {
                    transferTarget: 'HEIRS_AND_CONTACTS',
                    heirsDocumentSelection: newHeirsDocumentSelection,
                    contactsDocumentSelection,
                },
            });
        }, [vaultPreferences, updateVaultPreferences]);
        return {
            updateVaultPreferencesSaveSpecific,
            isPending,
            error,
        };
    };
    return {
        useVaultInfo,
        useVaultPreferences,
        useUpdateVaultPreferencesSaveWholeVault,
        useUpdateVaultPreferencesSaveSpecific,
        useUpdateVaultPreferencesTransferTarget,
        useUpdateVaultPreferencesDelete,
    };
};
export default createVaultModule;
